
import { Options, Vue } from "vue-class-component";
//import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Team from "@/components/Team.vue";
import { getSiteName } from "@/utilities/UpdatePageTitle";

@Options({
  components: {
    // HelloWorld,
    Team,
  },
})
export default class About extends Vue {
  created() {
    document.body.className = "about";
  }

  unmounted() {
    document.body.className = "";
    //document.title = "";
  }

  mounted() {
    document.title = `About ${getSiteName()}`;
  }
}
