
import { getSiteName } from "@/utilities/UpdatePageTitle";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Support extends Vue {
  //  beforeCreate() {
  //       document.body.className = 'about';
  //   }

  mounted() {
    document.title = `Support ${getSiteName()}`;
  }
}
