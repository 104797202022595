
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Team extends Vue {
  getImgUrl(pic: string) {
    return require("../assets/" + pic);
  }

  data() {
    return {
      messages: ["hello", "vue", "js"],
      teamMembers: [
        {
          name: "Karim",
          title: "Senior UX Designer",
          image: "Karim.jpg",
        },
        {
          name: "Christopher",
          title: "Junior Frontend Developer",
          image: "Christopher.jpeg",
        },
        {
          name: "Julie",
          title: "Senior Frontend Developer",
          image: "Julie.jpeg",
        },
      ],
    };
  }
}
