
import { getSiteName } from "@/utilities/UpdatePageTitle";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Integrations extends Vue {
  mounted() {
    document.title = `Integrations ${getSiteName()}`;
  }
}
