
import { getSiteName } from "@/utilities/UpdatePageTitle";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Support extends Vue {
  mounted() {
    document.title = `What's New ${getSiteName()}`;
  }
  unmounted() {
    document.title = "";
  }
}
