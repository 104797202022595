
import { getSiteName } from "@/utilities/UpdatePageTitle";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
})
export default class Support extends Vue {
  data() {
    return {
      ZeroHeightLink: process.env.VUE_APP_ZERO_HEIGHT_LINK,
    };
  }
  mounted() {
    document.title = `Contribute ${getSiteName()}`;
  }
  unmounted() {
    document.title = "";
  }
}
