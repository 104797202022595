
import { Options, Vue } from "vue-class-component";
import WidgetAngularLibrary from "@/components/WidgetAngularLibrary.vue";

@Options({
  components: {
    WidgetAngularLibrary,
  },
})
export default class Components extends Vue {}
